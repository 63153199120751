#root {
  background: #62c84c;
}

#container {
  overflow-x: hidden;
}

.item-a {
  grid-area: header;
}

.grass {
  background: url("../../images/land/grass/tile023.png");
}


.grass1 {
  z-index: 3;
  opacity: 0.1;
  background: url("../../images/land/grass/tile024.png");
}


.cuisinier{
  /* width: 32px;
  height: 32px; */
  position: relative;
  width: 120%;
  bottom:20%;
  right: 20%;
  top: 14%;
}
.cuisinier2{
  /* width: 32px;
  height: 32px; */
  position: relative;
  width: 300%;
  bottom: 25px;
  right: 30px;
  /* right:30%; */
  
  /* top: 14%; */
}
.cuisinier3{
  /* width: 32px;
  height: 32px; */
  position: relative;
  width: 120%;
  bottom:20%
  /* top: 14%; */
}

.cow{
  position: relative;
  width: 200%;
  right: 10px;
  bottom: 30px;
  transform:scaleX(-1);
}
.cow2{
  position: relative;
  width: 150%;
  left: 10px;
  bottom: 30px;
  transform:scaleX(-1);
}
.gobdown{
  position: relative;
  width: 100%;
  bottom: 45%;
  right: 15%;
}

.arrosoir{
  position: relative;
  width: 300%;
  bottom: 120%;
  right: 40%;
}

.gauche{
  position: relative;
  width: 120%;
}


/* MOUETTE ANIMATION */
.flying-seagul {
  position: absolute;
  height: 30px;
  width: 30px;
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 1;
}

.moving-cloud {
  width: 100px;
  position: absolute;
  animation-duration: 56s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 999;
}


.cloud-to-right{
  left:-105px;
  animation-name: cloudMoveToRight;
}

.cloud-to-left{
  right:-50px;
  animation-name: cloudMoveToleft;
}

#flying-seagul-to-right-3{
  bottom:800px;
  animation-delay: 2s;
}

#flying-seagul-to-right-2{
  bottom:200px;
  animation-delay: 5s;
}
.flying-seagul-to-right{
  left:-95px;
  animation-name: flightToRight;
}

.flying-seagul-to-left{
  right:-35px;
  animation-name: flightToLeft;
}
#flying-seagul-to-left-1{
  top:35px;
  animation-delay: 2s;
}


#cloud-to-left-1{
  width:60px;
  top:700px;
  animation-delay: 2s;
}

#cloud-to-left-2{
  width:60px;
  top:355px;
  animation-delay: 8s;
}

#cloud-to-right-1{
  width:80px;
  top:35px;
  animation-delay: 2s;
}


@-webkit-keyframes flightToLeft {
  0% {
    right: 0;
  }
  50% {
    right: 50%;
  }
  100% {
    right: 100%;
  }
}

@-webkit-keyframes flightToRight {
  0% {
    left: 0;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 100%;
  }

}

@-webkit-keyframes cloudMoveToRight {
  0% {
    left: 0;
  }
  30% {
    left: 25%;
  }
  50% {
    left: 50%;
  }
  70% {
    left: 75%;
  }
  100% {
    left: 100%;
  }
}

@-webkit-keyframes cloudMoveToleft {
  0% {
    right: 0;
  }
  30% {
    right: 25%;
  }
  50% {
    right: 50%;
  }
  70% {
    right: 75%;
  }
  100% {
    right: 100%;
  }
}

/* FIN MOUETTE ANIMATION */

.gobdown2{
  position: relative;
  width: 100%;
}
.gobelin{
  position: relative;
  width: 100%;
  right:5%;
}
.gobdownCheveu{
  position: relative;
  width: 100%;
  left: 50%;
}

/* //TEST */
/**2,625*/
.Fond {
  position: absolute;
  width: 1008px;
  height: 1008px;
}

.farm {
  display: grid;
  grid-template-columns: repeat(32, 1fr);
  grid-template-rows: repeat(32, 1fr);
  grid-auto-rows: 1fr;
  width: 1008px;
  height: 1008px;
  margin: auto;
  overflow-y: clip;
  position: relative;
}

.farm > div {
  height: calc(1008px / 31.5);
  width: calc(1008px / 31.5);
  background-size: cover;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}


.upgrade-land {
  position: absolute;
  width: 95px;
  text-align: center;
  top: 11px;
  left: -18px;
}

#box1 {
  width: 66%;
  position: relative;
  left: 63%;
  top: 10%;
}

#cauliflower-box {
  top: 9%;
  position: absolute;
  width: 34%;
  left: 78%;
}

#potato-box-1 {
  left: 19%;
  width: 25%;
  top: -41%;
  position: absolute;
}

#potato-box-2 {
  left: 43%;
  width: 25%;
  top: -41%;
  position: absolute;
}

#sunflower-box-1 {
  left: 19%;
  width: 25%;
  top: -50%;
  position: absolute;
}

#sunflower-box-2 {
  left: 43%;
  width: 25%;
  top: -50%;
  position: absolute;
}

#pumpkin-box-1 {
  left: 72%;
  width: 25%;
  top: 10%;
  position: absolute;
}

#pumpkin-box-2 {
  left: 95%;
  width: 25%;
  top: 10%;
  position: absolute;
}

#box2 {
  width: 66%;
  position: relative;
  top: -48%;
  left: 13%;
}

.fence-horizontal {
  width: 100%;
  position: relative;
  left: 33%;
  bottom: -7%;
  height: 60%;
}

.fence-post {
  width: 52%;
  position: relative;
  left: 27%;
  bottom: -7%;
  height: 60%;
}

.fence-half {
  width: 89%;
}

.water-edge {
  width: 62.5px;
  height: 62.5px;
  z-index: 99;
  top: 537.5px;
  image-rendering: pixelated;
}


.water-top {
  width: 62.5px;
  height: 62.5px;
  z-index: 99;
  top: 0;
  image-rendering: pixelated;
}

#water {
  background: #0099db;
  width: 100vw;
}

#water-background {
  /* background: #62c84c; */
  background-image: url("../../images/land/grass/Water_3.png");
  position: absolute;
  width: 100vw;
  height:100vh;
  overflow: auto;
}
/*
background-image: url("/media/sea.png"); ;
*/
#water-left {
  background: #2399db;
  position: absolute;
  top: 0;
  width: 20%;
  height: 500%;
  left: 0;
}

#water-right {
  background: #2399db;
  position: absolute;
  top: 0;
  width: 20%;
  height: 500%;
  right: 0;
}

.stone-vertical {
  height: 200%;
  width: 28px;
  left: 32%;
  top: 100%;
  position: relative;
}

.stone-bl {
  width: 35px;
  top: 297%;
  position: absolute;
  left: 24px;
}

.stone-horizontal {
  width: 300%;
  height: 42px;
  left: 47px;
  position: absolute;
  top: 299%;
}

.upgrade-overlay {
  height: 100% !important;
  width: 200%;
  cursor: url(../../images/ui/cancel.png) 0 0, pointer;
  z-index: 1000;
  position:absolute;
  font-color:red;
}

.bucket {
  width: 89%;
}

.flowers {
  width: 79%;
  position: relative;
  top: 10%;
  left: 6%;
}

.stump {
  width: 70%;
  position: relative;
  top: 15%;
  left: 10%;
}

.leaves {
  width: 70%;
  position: relative;
  top: 15%;
  left: 10%;
}

.rock1 {
  width: 60%;
  position: relative;
  left: 21%;
  top: 20%;
}

.bridge {
  left: 0;
  width: 135%;
  z-index: 999;
  position: absolute;
  top: -66%;
}

.man {
  width: 63%;
}

.girl {
  width: 74%;
  position: relative;
  left: -17px;
  top: -1px;
}

.carrotMan {
  width: 92%;
  position: relative;
  left: -23%;
}

.baldMan {
  width: 120%;
  position: relative;
  top: 100%;
}

.baldMan {
  width: 120%;
  position: relative;
  top: 100%;
}

.baldMan-halving {
  width: 80%;
  position: relative;
  top: 14%;
}


.chat {
  width: 80%;
  top: -90%;
  left: 50%;
  position: relative;

  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  margin-top: 5px;
}

.chat2 {
  width: 100%;
  top: -180%;
  left: 0%;
  position: relative;

  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  margin-top: 5px;
}


.chat-event {
  width: 82%;
  top: -112%;
  left: 10%;
  position: relative;

  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  margin-top: 5px;
}

.halving-chat{
  width: 55%;
  top: -95%;
  left: 40%;
  position: relative;

  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  margin-top: 5px;
}

#salesman:hover {
  transform: scale(1.1);
  cursor: pointer;
}

#environment-left {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(16, 1fr);
  grid-auto-rows: 1fr;
  width: 1000px;
  left: 100%;
  height: 100%;
}

#environment-right {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(16, 1fr);
  grid-auto-rows: 1fr;
  width: 1000px;
  left: -100%;
  height: 100%;
}

#present {
  width: 70%;
  cursor: pointer;
  position: relative;
  top: 10px;

  animation: shake-animation 4.72s ease infinite;
  transform-origin: 50% 50%;
}

#present:hover {
  opacity: 0.8;
}

@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(5px, 0);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(5px, 0);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(5px, 0);
  }
  10.71429% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}


#crafting {
  display: flex;
  min-height: 330px;
}
.box-panel {
  background: #b96f50;
  padding: 5px;
  margin: 5px;
  display: flex;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.box-pixel-panel:hover {
  background: #c28569;
}

.box-active {
  background: #c28569 !important;
}

.box-disabled {
  background: #945542 !important;
  cursor: default;
}

.box-pixel-panel {
  background: #945542;
  flex: 1;
  width: 100%;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-item {
  min-height: 75%;
  max-height: 40px;

  image-rendering: pixelated;
}

#crafting-items {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  min-height: 124px;
}

#recipe {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #9f6c54;
  padding: 7px;
  padding-top: 14px;
  min-height: 300px;
  z-index: 9;
}

#crafting-item {
  height: 120px;
  display: flex;
}

#crafting-item > img {
  height: 100%;
  image-rendering: pixelated;
  margin: auto;
  filter: drop-shadow(1px 1px 0px white) drop-shadow(-1px 0px 0px white)
  drop-shadow(0px -1px 0px white);
}

#recipe-title {
  color: white;
  text-shadow: 1px 1px black;
  text-align: center;
  padding-bottom: 4px;
  padding-top: 4px;
}

#recipe-description {
  text-align: center;
  color: white;
  text-shadow: 1px 1px black;
  font-size: 11px;
  margin-top: 12px;
}

#craft-button-text {
  font-size: 12px;
}

#craft-action {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}

#craft-count {
  display: flex;
}

.craft-arrow {
  display: -webkit-flex;
  display: flex;
  width: 11px;
  cursor: pointer;
  margin-bottom: 3px;
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated;
}

#crafting-left {
  display: flex;
  flex-direction: column;
}

#community-left {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 4px;
  width: 300px;
  padding-bottom: 16px;
}

.ingredient {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ingredient-image {
  width: 20px;
  image-rendering: pixelated;
  margin-right: 7px;
}

.ingredient-text {
  color: white;
  text-shadow: 1px 1px black;
  font-size: 12px;
}

.ingredient-insufficient {
  color: #f6757a;
}

.ingredient-count {
  color: white;
  text-shadow: 1px 1px black;
  font-size: 12px;
}

#ingredients {
  border-top: 1px solid white;
  margin-top: 8px;
  padding-top: 4px;
  border-bottom: 1px solid white;
  margin-bottom: 8px;
  padding-bottom: 4px;
}

#inventory-header {
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 4px;
  align-items: center;
}

#inventory-header > img {
  width: 25px;
  height: 25px;
  image-rendering: pixelated;
  margin-right: 4px;
}

#inventory-header > span {
  color: white;
  text-shadow: 1px 1px black;
  font-size: 13px;
}

#inventory {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  min-height: 124px;
}

#inventory-loading {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 300px;
  height: 200px;
  color: white;
  text-shadow: 1px 1px black;
  padding: 10px;
}

.inventory-box {
  width: 50px;
  height: 50px;
}

.box-count {
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 9999;
  font-size: 12px;
  background: #bfcbda;
  padding: 0 3px;
  border-radius: 6px;
  border: 2px solid black;
  outline: white;
  color: black;
  text-shadow: 1px 1px white;
}

.recipe-type {
  position: absolute;
  top: 0px;
  right: 0;
  font-size: 9px;
  color: white;
  text-shadow: 1px 1px black;
  padding: 2px 7px;
  border-radius: 3px;
}

.recipe-nft {
  background: #2399db;
}

.recipe-erc20 {
  background: #fbad34;
}

.nft-count {
  position: absolute;
  background: #2399db;
  top: 0px;
  right: 70px;
  font-size: 9px;
  color: white;
  text-shadow: 1px 1px black;
  padding: 2px 7px;
  border-radius: 3px;
}

.community-title {
  color: white;
  text-shadow: 1px 1px black;
  font-size: 12px;
  display: block;
  padding-left: 4px;
  padding-bottom: 4px;
  text-decoration: underline;
}

.community-description {
  color: white;
  text-shadow: 1px 1px black;
  font-size: 12px;
  display: block;
  padding-left: 4px;
  padding-bottom: 4px;
}

#community-footer {
  display: flex;
  justify-content: space-between;
}

.community-guide-text {
  font-size: 12px;
  color: white;
  text-shadow: 1px 1px black;
  padding: 8px;
}

#approving-animation {
  position: relative;
  height: 50px;

  animation-name: running;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

#approving-goblin {
  position: absolute;
  height: 100px;
  left: -68px;
  top: -12px;
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated;
  z-index: 9;
}

#approving-sff {
  position: absolute;
  height: 19px;
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated;
  z-index: 99;
}
@keyframes running {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(100%, 0px);
  }
}

#community-error {
  color: white;
  text-shadow: 1px 1px black;
  font-size: 12px;
  padding: 8px;
}

#discord {
  font-size: 10px;
}
#discord > img {
  display: inline-block;
  width: 20px;
  image-rendering: pixelated;
  border-radius: 5px;
  position: relative;
  left: -5px;
  top: -2px;
}

#seagul {
  position: absolute;
  left: -58px;
  top: -25px;
  width: 30px;
  z-index: 99;
  pointer-events: none;
}
#decoration-rock {
  position: absolute;
  left: -58px;
  top: -25px;
  width: 25px;
  z-index: 99;
  pointer-events: none;
}

#black-girl {
  position: absolute;
  left: -1px;
  top: -1px;
  width: 30px;
  z-index: 99;
  pointer-events: none;
}
#chateau {
  position: absolute;
  left: 9px;
  top: -12px;
  width: 49px;
  z-index: 99;
  pointer-events: none;
}

#box {
  position: absolute;
  left: -55px;
  top: -55px;
  width: 25px;
  z-index: 99;
  pointer-events: none;
}


#bucket {
  position: absolute;
  left: -55px;
  top: -55px;
  width: 25px;
  z-index: 99;
  pointer-events: none;
}

#swimmer{

}


#palm {
  position: absolute;

  z-index: 2;
  pointer-events: none;
}

.eventMan{
  width: 80%;
  position: relative;
  top: 14%;
}

.eventMan-chat {
  width: 72%;
  top: -85%;
  left: 12%;
  position: relative;

  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  margin-top: 5px;
}

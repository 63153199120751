#panel-left-edge {
  position: absolute;
  left: -5px;
  top: 0px;
  height: 100%;
  width: 5px;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

#panel-right-edge {
  position: absolute;
  right: -5px;
  top: 0px;
  height: 100%;
  width: 5px;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

#panel-bottom-edge {
  position: absolute;
  left: 0px;
  bottom: -5px;
  width: 100%;
  height: 5px;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

#panel-top-edge {
  position: absolute;
  left: 0px;
  top: -5px;
  width: 100%;
  height: 5px;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

#panel-top-left {
  position: absolute;
  left: -5px;
  top: -5px;
  width: 5px;
  height: 5px;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

#panel-bottom-left {
  position: absolute;
  left: -5px;
  bottom: -5px;
  width: 5px;
  height: 5px;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

#panel-bottom-right {
  position: absolute;
  right: -5px;
  bottom: -5px;
  width: 5px;
  height: 5px;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

#panel-top-right {
  position: absolute;
  right: -5px;
  top: -5px;
  width: 5px;
  height: 5px;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

.pixel-panel {
  background: #b96f50;
  padding: 5px;
  display: flex;
  box-shadow: 0px 3px 5px 4px black;
}

.panel-tabs {
  padding-top: 45px !important;
}

.inner-pixel-panel {
  background: #c28569;
  flex: 1;
  width: 100%;

  position: relative;
}

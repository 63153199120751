#charity-container {
  font-size: 20px;
  color: white;
  text-shadow: 1px 1px black;
  text-align: center;
}

#charities {
  margin-top: 20px;
}

.charity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
}

#old-account-message-container {
  text-align:center;
  color:white;
  padding:15px;
  width:300px;
  margin-bottom: 7px;
}

#button-container{
  text-align:center
}

#old-account-button {
  width:374px;
  margin:auto;
  margin-top:20px;
}

.old-account-text{
  color:white;
  font-size: 12px;
}

#charities > div {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #b86f50;
  margin: 10px;
  border-radius: 6px;
}

#charities > div > .button {
  display: flex;
  margin-top: 16px;
  width: 111px;
}

#charities > div > .message {
  font-size: 14px;
  display: flex;
}

#donate-description {
  display: block;
  font-size: 13px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.charity-description {
  font-size: 12px;
  text-align: start;
  padding: 7px;
}

.charity-buttons {
  display: flex;
  justify-content: flex-end;
}

.charity-buttons > .button {
  font-size: 12px;
}

.total-donated {
  font-size: 12px;
  text-align: start;
  padding: 10px;
  width: fit-content;
  cursor: pointer;
}

#donation-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#donation-input {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  background: #e6a873;
  color: white;
  text-shadow: 1px 1px black;
  width: 130px;
  text-align: center;
  margin-right: 10px;
}

/* Hide arrows */
#donation-input::-webkit-outer-spin-button,
#donation-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#donation-input[type="number"] {
  -moz-appearance: textfield;
}
.arrow {
  display: flex;
  width: 18px;
  cursor: pointer;
  margin-bottom: 3px;
  image-rendering: pixelated;
}

.arrow:hover {
  opacity: 0.7;
}

#donate-minimum-description {
  display: block;
  font-size: 9px;
  margin-top: 4px;
}

@font-face {
  font-family: "Press Start 2p";
}

body {
  font-family: "Press Start 2p";
  /* background-color: #6fedfa !important; */
 
}

#balance {
  position: fixed;
  top: 15px;
  right: 10px;
  z-index: 777;
}

#buy-now {
  position: fixed;
  top: 260px;
  right: 0;
  z-index: 999;
  cursor: pointer;

  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.596));
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.596));
}

#buy-now:hover {
  -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.596));
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.596));
}

#inner {
  font-size: 15px;
  color: white;
  text-shadow: 1px 1px black;
  border-radius: 4px 0 0 4px;
  padding: 5px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#inner > img {
  width: 20px;
  margin-right: 10px;
  image-rendering: pixelated;
  filter: drop-shadow(1px 1px 0px white) drop-shadow(-1px 0px 0px white)
    drop-shadow(0px -1px 0px white);
}

#edge {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  position: absolute;
  height: calc(100% + 8px);
  top: -4px;
  left: -15px;
}

#panel-bg {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  position: absolute;
  height: calc(100% + 8px);
  top: -4px;
  left: 0px;
  right: 14px;
  z-index: -1;
  width: 100%;
}

#save-button {
  position: fixed;
  top: 15px;
  left: 10px;
  z-index:777;
}
/* HERE */
#ZoneAdmin {
  position: fixed;
  top: 50px;
  left: 10px;
  z-index:777;
  width:770px;
}
#ZoneInput {
  width:35%;
  margin-left: 10px;
}

#Global
{
    width:100%;
}
#Burn
{
    width:50%;
}
#Global {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

#gauche {
  flex: 2;
  display: flex;
 
  padding-right: 10px;
}
#droite {
  flex: 1;
}

.small-input {
  width: 100%;
  max-width: 60px;
  padding: 5px;
  text-align: center;
}

.prices-display,
.gains-display {
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-top: 5px;
}

.prices-display p,
.gains-display p {
  margin: 0;
  padding: 2px 0;
  text-align: left;
}


.message {
  background: #c0cbdb;
  position: relative;
  padding: 2px 4px;
  color: white;
  font-size: 10px;
  text-shadow: 1px 1px black;
  margin: 5px 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  
  
}
/* Mobile */
@media screen and (max-width: 767px) {
  #buy-now {
    display: none;
  }

  #first-soil{
    position: absolute;
    grid-area: 11 / 14 / 12 / 15 !important;
    left: -32px;
    bottom:10px;
  }

  #second-soil{
    position: absolute;
    grid-area: 11 / 15 / 12 / 16 !important;
    left: -5px;
    bottom:10px;
  }

  #three-soil{
    position: absolute;
    grid-area: 11 / 16 / 12 / 17 !important;
    left: 20px;
    bottom:10px;
  }

  #four-soil{
    position: absolute;
    grid-area: 11 / 17 / 12 / 18 !important;
    left: 45px;
    bottom:10px;
  }

  #five-soil{
    position: absolute;
    grid-area: 11 / 18 / 12 / 19 !important;
    left: 70px;
    bottom:10px;
  }

/* second zone */
  #six-soil {
    position: absolute;
    grid-area: 14 / 15 / 15 / 16 !important;
    left: -3px;
    bottom:10px;
  }
  #first-upgrade-overlay{
    position: absolute;
    grid-area: 14 / 15 / 15 / 16 !important;
    left: -3px;
    bottom:10px;
  }
  #seven-soil{
    position: absolute;
    grid-area: 14 / 17 / 15 / 18 !important;
    left: 11px;
    bottom:10px;
  }
  #second-upgrade-overlay{
    position: absolute;
    grid-area: 14 / 17 / 15 / 18 !important;
    left: 11px;
    bottom:10px;
  }
  #eight-soil{
    position: absolute;
    grid-area: 14 / 16 / 15 / 17 !important;
    left: 55px;
    bottom:10px;
  }
  #three-upgrade-overlay{
    position: absolute;
    grid-area: 14 / 16 / 15 / 17 !important;
    left: 55px;
    bottom:10px;
  }



  #nine-soil{
    position: absolute;
    grid-area: 17 / 15 / 18 / 16 !important;
    left: -3px;
    bottom:10px;
    top:20px;
  }
  #four-upgrade-overlay{
    position: absolute;
    grid-area: 17 / 15 / 18 / 16 !important;
    left: -3px;
    bottom:10px;
    top:20px;
  }
  #ten-soil{
    position: absolute;
    grid-area: 17 / 17 / 18 / 18 !important;
    left: 11px;
    bottom:10px;
    top:20px;
  }
  #five-upgrade-overlay{
    position: absolute;
    grid-area: 17 / 17 / 18 / 18 !important;
    left: 11px;
    bottom:10px;
    top:20px;
  }
  #eleven-soil{
    position: absolute;
    grid-area: 17 / 16 / 18 / 17 !important;
    left: 55px;
    bottom:10px;
    top:20px;
  }
  #six-upgrade-overlay{
    position: absolute;
    grid-area: 17 / 16 / 18 / 17 !important;
    left: 55px;
    bottom:10px;
    top:20px;
  }

  #twelve-soil{
    top: 10px;
    position: absolute;
    grid-area: 21 / 15 / 22 / 16 !important;
    left: -3px;
    bottom:10px;
    top:20px;
  }
  #seven-upgrade-overlay{
    top: 10px;
    position: absolute;
    grid-area: 21 / 15 / 22 / 16 !important;
    left: -3px;
    bottom:10px;
    top:20px;
  }
  #thirteen-soil{
    top: 10px;
    position: absolute;
    grid-area: 21 / 17 / 22 / 18 !important;
    left: 11px;
    bottom:10px;
    top:20px;
  }
  #eight-upgrade-overlay{
    top: 10px;
    position: absolute;
    grid-area: 21 / 17 / 22 / 18 !important;
    left: 11px;
    bottom:10px;
    top:20px;
  }
  #fourteen-soil{
    top: 10px;
    position: absolute;
    grid-area: 21 / 16 / 22 / 17 !important;
    left: 55px;
    bottom:10px;
    top:20px;
  }
  #nine-upgrade-overlay{
    top: 10px;
    position: absolute;
    grid-area: 21 / 16 / 22 / 17 !important;
    left: 55px;
    bottom:10px;
    top:20px;
  }

  /* last zone */

  #fifteen-soil{
    position: absolute;
    grid-area: 26 / 15 / 27 / 16 !important;
    left: -3px;
    bottom:15px;
    top:-10px;
  }
  #ten-upgrade-overlay{
    position: absolute;
    grid-area: 26 / 15 / 27 / 16 !important;
    left: -3px;
    bottom:15px;
    top:-10px;
  }
  #sixteen-soil{
    position: absolute;
    grid-area: 26 / 17 / 27 / 18 !important;
    left: 11px;
    bottom:15px;
    top:-10px;
  }
  #eleven-upgrade-overlay{
    position: absolute;
    grid-area: 26 / 17 / 27 / 18 !important;
    left: 11px;
    bottom:15px;
    top:-10px;
  }
  #seventeen-soil{
    position: absolute;
    grid-area: 26 / 16 / 27 / 17 !important;
    left: 55px;
    bottom:15px;
    top:-10px;
  }
  #twelve-upgrade-overlay{
    position: absolute;
    grid-area: 26 / 16 / 27 / 17 !important;
    left: 55px;
    bottom:15px;
    top:-10px;
  }


  #save-button {
    width: 30%;
  }

  #deco {
    display: none;
  }
  #cow{
    position: absolute;
    left:170px;
    bottom:0px;
  }
  #cuisinier2{
    position: absolute;
    left:-85px;
    bottom:-155px;
  }
  #arrosoir{
    position: absolute;
    left:-23px;
    bottom:140px;
  }
   #salesman {
    display: none;
  }

  #aboutbutton {
    display: none;
  }

  #audio-player-container {
    display: none;
  }

  .Fond {
    display: none;
  }

  .farm {
    display: grid;
    grid-template-columns: repeat(36, 1fr);
    grid-template-rows: repeat(32, 1fr);
    grid-auto-rows: 1fr;
    width: 100%;
    height: 100%;
    margin: auto;
    /* overflow-y: clip; */
    position: relative;
  }

  #balance {
    width: 40%;
    right: 0;
  }

  #water-background {
    background-image: url("images/land/grass/plain.png");
    width: 100%;
    height: 100%;
  }

  .grass {
    background: url("images/land/grass/plain.png");
  }

  .grass1 {
    z-index: 3;
    opacity: 0.1;
    background: url("images/land/grass/plain.png");
  }

  #flying-seagul-to-left-1 {
    display: none;
  }

  #flying-seagul-to-right-2 {
    display: none;
  }

  #flying-seagul-to-right-3 {
    display: none;
  }

  .zone-island-title {
    text-align: center;
    color: white;
    top: 25%;
    left: 40%;
    position: absolute;
  }

  .zone-island-title2 {
    text-align: center;
    color: white;
    top: 35%;
    left: 40%;
    position: absolute;
  }

  .zone-island-title3 {
    text-align: center;
    color: white;
    top: 49%;
    left: 40%;
    position: absolute;
  }

  .zone-island-title4 {
    text-align: center;
    color: white;
    top: 60%;
    left: 40%;
    position: absolute;
  }

  .zone-island-title5 {
    text-align: center;
    color: white;
    top: 73%;
    left: 40%;
    position: absolute;
  }

  #cloud-to-left-1 {
    display: none;
  }

  #cloud-to-left-2 {
    display: none;
  }

 

  .disc {
    display: none;
  }

  .dig {
    position: absolute;
    color: #38242d;
    font-size: 14px;
    z-index: 2;
    cursor: pointer;
    margin-top: 5px;
    -webkit-animation-name: none;
    animation-name: none;
  }

  .board {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
  .element-board-modal {
    min-width: 150px;
  }

  #timer {
    display: none;
  }

  #crafting {
    display: -webkit-flex;
    display: contents;
    min-height: 330px;
  }

  #Updatebutton {
    position: absolute;
    top: 115px;
    width: 32%;
    left: 4px;
    text-align: center;
  }
  #basket{
    right: 12px;
  }
}
.button {
  background: #e6a873;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  padding: 2px 10px;
  color: white;
  font-size: 15px;
  text-shadow: 1px 1px black;
  box-shadow: 0px 3px 2px 2px black;
  cursor: pointer;
  margin: 5px 10px;
}

.disabled {
  opacity: 0.5;
}

.button > img {
  margin-left: 3px;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

#exclamation {
  width: 10px;
}

#question {
  width: 14px;
  height: 20px;
}

.button:hover {
  opacity: 0.8;
}

.header {
  color: white;
  text-shadow: 1px 1px black;
  font-size: 27px !important;
  padding: 14px;
}

#welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 0;
  align-items: center;
  padding: 30px 30px 28px;
  text-align: center;

  color: white;
  text-shadow: 1px 1px black;
}

#welcome .button {
  margin-bottom: 10px;
  width: 50%;
  justify-content: center;
}

.farm-tour {
  color: white !important;
  text-shadow: 1px 1px black !important;
  background-color: #b86f50 !important;
}

.reactour__close {
  color: white !important;
}

#tour-button {
  text-align: center;
  margin-top: 22px;
  width: 98px;
}

#logo {
  max-width: 400px;
  margin-top: -20px;
  margin-bottom: 10px;
}

* {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
      Introduced in Internet Explorer 10.
      See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
    */
  -ms-user-select: none;
  user-select: none;
}



#choiceButtonContainer{
  width:100%;
}

#choiceButtonContainer .button {
  width: 70%;
  justify-content: center;
  margin:auto;
  margin-top: 25px;
  margin-bottom: 10px;
}

choiceIslandButtonContainer {
  width:100%;
}

#choiceIslandButtonContainer .button {
  width: 70%;
  justify-content: center;
  margin:auto;
  margin-top: 25px;
  margin-bottom: 10px;
}

#BackButtonContainer{
  widht:100%;
}

#BackButtonContainer .button {
  width: 50%;
  justify-content: center;
  margin:auto;
  margin-top: 25px;
  margin-bottom: 10px;
}

.red-text{
  color:red;
}

.orange-text{
  color:orange;
}

.blue-text{
  color:lightskyblue;
}

.doge-text{
  margin-top:30px;
  color:#97cde5;
}

#back-announce{
  margin-bottom:20px;
}

#island-name {
  position: fixed;
  top: 80px;
  right: 0;
  z-index: 999;
  cursor: pointer;

  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.596));
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.596));
}

#island-name:hover {
  -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.596));
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.596));
}


#warning{
  padding-top:100px;
  width:700px;
  margin:auto;

}

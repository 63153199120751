.price-header {
  color: white;
  text-shadow: 1px 1px black;
  font-size: 17px;
  display: flex;
  align-items: baseline;
}

.price-alert {
  width: 13px;
  image-rendering: pixelated;
  margin-left: 10px;
}
.element1-price{
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  width: 26px;
  filter: drop-shadow(1px 1px 0px white) drop-shadow(-1px 0px 0px white)
    drop-shadow(0px -1px 0px white);
}

.current-price-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.current-price-header {
  color: white;
  text-shadow: 1px 1px black;
  font-size: 11px;
  margin-top: 30px;
}

.current-price-prediction {
  color: white;
  text-shadow: 1px 1px black;
  margin-top: 0;
  font-size: 18px;
  margin-bottom: 20px;
}

#prediction-alert {
  image-rendering: pixelated;
  width: 12px;
  margin-right: 8px;
}

.current-price-supply-demand {
  color: white;
  text-shadow: 1px 1px black;
  font-size: 11px;
  margin-top: 4px;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 4px;
}

.current-price-subheader {
  color: white;
  text-shadow: 1px 1px black;
  font-size: 11px;
  margin-top: -7px;
}

.current-price {
  color: white;
  text-shadow: 1px 1px black;
  font-size: 18px;
  margin-left: 15px;
}

.current-price-info-container {
  margin: 10px 0;
}

.current-price-info {
  font-size: 11px;
}


.element-price {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  width: 26px;
  filter: drop-shadow(1px 1px 0px white) drop-shadow(-1px 0px 0px white)
  drop-shadow(0px -1px 0px white);
}

#doge-container {
  with:100%;
}
.doge {
  margin-left: 290px;
  height: 140px;
  right: 0px;
  width: 138px;
}

#timer-complete {
  color: white;
  text-shadow: 1px 1px black;
  text-align: center;
  line-height: 20px;
  padding: 10px;
}

#timer-complete > h6 {
  padding-top: 16px;
}

#timer-complete > p {
  padding-top: 16px;
  padding-bottom: 30px;
}

#timer-complete > span {
  font-size: 10px;
}

#timer-complete > img {
  height: 40px;
  margin-bottom: 20px;
  image-rendering: pixelated;
}

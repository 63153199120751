.dig {
  position: absolute;
  color: #38242d;
  font-size: 9px;
  z-index: 2;
  /* top: 22px; */
  cursor: pointer;
  /* top: -58%;*/
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-top: 5px;
}

.loop {
  animation-direction: alternate;
}

.mint {
  position: absolute;
  color: #38242d;
  font-size: 10px;
  z-index: 2;
  top: 22px;
  cursor: pointer;
  left: 79%;
  top: 200%;
  z-index: 9;
}

#minter {
  cursor: pointer;
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 5px);
  }
  to {
    transform: translate(0, -0px);
  }
}

.disc {
  position: absolute;
  left: -12px;
  top: 0;
  z-index: 1;
}

.dig .inner-pixel-panel {
  padding-left: 5px;
}

.discBackground {
  position: absolute;
  width: 34px;
  left: -18px;
  top: -9px;

  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  z-index: 2;
}

.pickaxe {
  position: absolute;
  width: 27px;
  left: -15px;
  top: -4px;

  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  z-index: 2;
}

#upgrade {
  color: white;
  padding: 0 13px;
  text-shadow: 1px 1px black;
  position: relative;
  white-space: nowrap;
}
